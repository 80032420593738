<template>
	<div>
		<div class="sidebar-row">
			<h3 class="row-header"><strong>Current Directory:</strong> <span :title="currentDirectory">{{trimDirectoryPath(currentDirectory)}}</span></h3>

			<div class="row-body">
				<connection
					:entry="entry"
					:droneLink="droneLinks.currentDirectory"
					:connectionString="connectionStrings.current"
					:visibility="{
						'ftpExplorerButton': false,
					}"
				/>

				<ul class="row-actions">
					<li>
						<a
							:href="getArchiveActionUrl()"
							target="_blank"
						>
							<i class="fa fa-file-zip-o"></i>
							Изтегли архив
						</a>
					</li>
				</ul>
			</div>
		</div>

		<div v-if="getWpRoot()" class="sidebar-row">
			<h3 class="row-header"><strong>WP Instance:</strong> <span :title="getWpRoot()">{{trimDirectoryPath(getWpRoot())}}</span></h3>

			<div class="row-body">
				<connection
					:entry="entry"
					:droneLink="droneLinks.wpRootDirectory"
					:connectionString="connectionStrings.wp"
					:visibility="{
						'ftpExplorerButton': false,
					}"
				/>

				<ul class="row-actions">
					<li>
						<a
							:href="getDumpDbUrl()"
							target="_blank"
						>
							<i class="fa fa-archive"></i>
							Изтегли база данни
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * The external dependencies.
 */
import { mapGetters } from 'vuex';

/**
 * The internal dependencies.
 */
import Connection from 'components/access-depot/Connection.vue';

export default {
	props: [
		'currentDirectory',
		'droneLinks',
		'entry',
		'connectionStrings',
		'wpRoot',
	],

	components: {
		Connection,
	},

	data() {
		return {};
	},

	methods: {
		getWpRoot() {
			return this.wpRoot;
		},

		trimDirectoryPath(directoryPath) {
			let text = directoryPath;

			const maxChars = 35;
			if (text.length > maxChars) {
				text = '...' + text.slice(maxChars * -1);
			}

			return text;
		},

		getDumpDbUrl() {
			let versionControlPath = encodeURIComponent(document.getElementById('svnpathinput').value);
			let wpRootPath         = encodeURIComponent(this.wpRoot);

			return '/deploy/frontend/act.php'
				+ '?act=export_db_remote'
				+ '&src_site=htmlburger'
				+ '&task_id=' + TASK_ID
				+ '&vcs_path=' + versionControlPath
				+ '&target=client-preview-2-cloaked'
				+ '&ad_entry_id=' + this.entry.id
				+ '&wp_root_path=' + wpRootPath;
		},

		getArchiveActionUrl() {
			let versionControlPath = encodeURIComponent(document.getElementById('svnpathinput').value);
			let archiveTargetPath  = encodeURIComponent(this.currentDirectory);

			return '/deploy/frontend/act.php'
				+ '?act=export_archive_remote'
				+ '&src_site=htmlburger'
				+ '&task_id=' + TASK_ID
				+ '&vcs_path=' + versionControlPath
				+ '&target=client-preview-2-cloaked'
				+ '&ad_entry_id=' + this.entry.id
				+ '&archive_target_path=' + archiveTargetPath;
		},
	}
};
</script>

<style scoped>
	.acd-item-button { float: none; display: inline-block; border-left: 1px solid #999; }
	code { display: inline-block; }
	.sidebar-row { border: 1px solid #999; }
	.sidebar-row:first-of-type .acd-actions { padding-top: 0; }
	.sidebar-row + .sidebar-row { margin-top: 12px; }
	.sidebar-row .row-header { padding: 8px 12px; background-color: #f5f5f5; border-bottom: 1px solid #999; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; font-weight: normal; }
	.sidebar-row .row-body { padding: 12px; }
	.sidebar-row .connection-string-holder-tr { padding: 0; }
	.sidebar-row .connection-string-holder-tr:after { display: table; content: ''; clear: both; }
	.sidebar-row .row-actions { padding: 14px 0 0; list-style: none; }
	.sidebar-row .row-actions li {  }
	.sidebar-row .row-actions a { text-decoration: none; color: #468fd3; }
	.sidebar-row .row-actions a i { color: #777; }
</style>

<style>
	.ftp-explorer .connection-string-holder-tr { float: none; width: 100%; display: flex; }
	.ftp-explorer .connection-string-masked { width: auto; flex: 1 1 auto; }
</style>