<template>
    <div class="company-email-wrap">
        <email-details v-if="details" :details="details" />
        <create-email v-else />

        <div class="email-loader" v-if="loading">
            <img src="/css/img/loading.gif" />
        </div>
    </div>
</template>

<script>
/**
 * External dependencies:
 */
import { mapGetters } from 'vuex';

/**
 * Internal dependencies:
 */
import EmailDetails from 'components/company-email/details.vue';
import CreateEmail from 'components/company-email/create.vue';

export default {
    components: {
        EmailDetails,
        CreateEmail,
    },

    computed: {
        ...mapGetters({
            details: 'companyEmail/getEmailDetails',
            loading: 'companyEmail/getLoading',
        }),
    },
}
</script>

<style scoped>
.email-loader { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255, 255, 255, 0.6); }
.email-loader img { position: absolute; top: 50%; left: 50%; transform: translate(-50%); }
</style>
