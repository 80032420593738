<template>
	<div
		:id="'comment-' + comment.id"
		:class="classList"
		:data-syntax="comment.syntax"
		>
		<span class="red-point" v-if="comment.red_point === 'Y'" />

		<div class="comment__head" @click="toggleClose" style="cursor: pointer;">
			<div class="comment__author" :style="'color: ' + comment.author.color">
				<a :href="'/' + comment.author.username" class="comment__author-avatar">
					<img :src="comment.author.avatar" alt="" width="27" height="27" />
				</a>

				<span class="comment__author-name" v-html="comment.author.name" />
			</div><!-- /.comment__author -->

			<comment-progress
				v-if="totalTasklistItems && ! this.isInlineEditting"
				:completed="completedTasklistItems"
				:total="totalTasklistItems"
			/>

			<span class="comment__excerpt" v-if="comment.type === 'comment' && closed" v-html="comment.excerpt" />

			<transition name="fade">
				<div class="comment__alert" v-if="message">
					<div :class="'alert-inline alert--' + messageType" v-html="message" />
				</div><!-- /.comment__alert -->
			</transition>

			<template v-if="isAction">
				<span :class="'comment__action actionhead action-icon icon-' + actionType" v-if="actionType === 'time-estimate'">
					Прогнозно време: <strong>{{ comment.human_time_estimate }}</strong>
				</span>

				<span :class="'comment__action actionhead action-icon icon-' + actionType" v-else-if="actionType === 'time-recorded'">
					Време за работа: <strong>{{ comment.human_time_taken }}</strong>
				</span>

				<span class="comment__action actionhead action-icon icon-commit" v-else-if="actionType === 'commit_all_files'">Commit</span>

				<span class="comment__action actionhead action-icon icon-orbit" v-else-if="actionType === 'orbit-review'">Orbit Review</span>

				<span class="comment__action actionhead action-icon" v-else-if="actionType === 'comment-ref'">
					<a :href="comment.reference.url"><i class="fa fa-external-link" aria-hidden="true"></i> Go to Comment</a>
				</span>

				<span class="comment__action actionhead action-icon" v-else-if="actionType === 'deploy_from_archive'">
					<i class="fa fa-caret-square-o-up icon-deploy-from-archive" aria-hidden="true"></i>
					Deploy from archive
				</span>

				<span class="comment__action actionhead action-icon" v-else-if="actionType === 'set_debug'">
					<i class="fa fa-bug"></i>
					Toggle debug mode
				</span>

				<span class="comment__action actionhead action-icon" v-else-if="actionType === 'export_db_remote'">
					<i class="fa fa-archive" aria-hidden="true"></i>
					Remote dump
				</span>

				<span class="comment__action actionhead action-icon" v-else-if="actionType === 'export_archive_remote'">
					<i class="fa fa-file-zip-o" aria-hidden="true"></i>
					Remote archive
				</span>

				<span :class="'comment__action actionhead action-icon icon-' + actionType" v-else>
					{{ upperFirst(actionType) }}
				</span>
			</template>

			<span class="comment__date" :title="comment.date_added + ' EEST'" v-html="comment.human_date_added" />

			<comment-actions
				:comment="comment"
				@copy-success="onCopy"
				@edit="onEdit"
				@delete="onDelete"
			/>
		</div><!-- /.comment__head -->

		<div class="comment__body">
			<div class="comment__diff" />

			<comment-editor
				v-if="isInlineEditting"
				:content="editedBody"
				:is-saving="isSaving"
				:disabled="isSaving"
				@save="onSave"
				@cancel="isInlineEditting = false"
			/>

			<div class="rte" v-show="! isInlineEditting && ! closed">
				<div class="comment__text" ref="commentBody" v-html="preview" />

				<div class="comment__files" v-if="comment.files.length > 0">
					<template v-if="comment.body_raw">
						<strong>Файлове:</strong>
					</template>

					<ul class="attachments">
						<li v-for="file in comment.files">
							<a :href="file.url" target="_blank">
								{{ file.filename }}
							</a>
						</li>
					</ul><!-- /.attachments -->
				</div><!-- /.comment__files -->
			</div><!-- /.rte -->

			<p class="time-taken">
				<template v-if="comment.time_estimate">
					Прогноза: {{ comment.human_time_estimate }}
				</template>

				<template v-if="comment.time_taken">
					Време за работа: {{ comment.human_time_taken }}
				</template>
			</p><!-- /.time-taken -->

			<div class="comment__meta">
				<comment-revisions
					v-if="comment && comment.revisions_count > 0"
					:comment="comment"
					@on-reset="handleReset"
					@on-revision-switch="handleRevisionSwitch"
					@on-revision-diff="handleLoadRevisionDiff"
				/>
			</div><!-- /.comment__meta -->

			<div class="comment-tags" v-if="comment.tags.length > 0">
				{{ comment.tags.map(tag => tag.name).join(', ') }}
			</div><!-- /.comment-tags -->
		</div><!-- /.comment__body -->
	</div><!-- /.comment -->
</template>

<script>
	/**
	 * External Dependencies.
	 */
	import { upperFirst } from 'lodash';
	import { mapGetters, mapActions, mapMutations } from 'vuex';

	/**
	 * Internal Dependencies.
	 */
	import { fetchCommentDiff, updateTaskListItem, decodeHtml } from './utils';
	import { $can } from '~/helpers/permissions';

	import CommentEditor from 'components/editor/editor.vue';
	import CommentActions from './CommentActions.vue';
	import CommentProgress from './CommentProgress.vue';
	import CommentRevisions from './CommentRevisions.vue';

	const sanitizeConfig = {
		ALLOWED_TAGS: ['input', 'p', 'ul', 'li', 'img', 'video', 'em', 'strong', 'code', 'a', 'pre'],
		ALLOWED_ATTR: ['type', 'checked', 'class', 'src', 'href'],
		ALLOW_DATA_ATTR: false,
		ALLOW_UNKNOWN_PROTOCOLS: false,
	};

	export default {
		/**
		 * Component Name.
		 *
		 * @type {String}
		 */
		name: 'comment',

		/**
		 * Component Props.
		 *
		 * @type {Object}
		 */
		props: {
			comment: Object,
			isFocused: {
				type: Boolean,
				default: false,
			},
		},

		/**
		 * Child Components.
		 *
		 * @type {Object}
		 */
		components: {
			CommentActions,
			CommentEditor,
			CommentProgress,
			CommentRevisions
		},

		/**
		 * Component Data.
		 *
		 * @return {Object}
		 */
		data() {
			let isClosed = (this.comment.type !== 'comment' || (! this.isFocused && ! this.comment.is_recent));

			return {
				closed: isClosed,

				preview: this.comment.body, // The content that is currently being viewed
				isRevision: false,

				$element: null,
				$commentBody: null,

				totalTasklistItems: 0,
				completedTasklistItems: 0,

				isSaving: false,
				isInlineEditting: false,
				isDeleting: false,

				error: null,
				message: null,
				messageType: null,
				messageTimeout: 2000,
				diff: null,

				editedBody: null, // The Content that is currently being edited
				editedRevision: null, // This keeps the ID of the previous revision

				copyingTimeout: null,
			};
		},

		/**
		 * Computed Data.
		 */
		computed: {
			...mapGetters({
				currentUser: 'currentUser/getCurrentUser',
				isAdmin: 'currentUser/isAdmin',
			}),

			classList() {
				let classList = ['single-comment'];

				if (this.isFocused) {
					classList.push('comment-in-focus');
				}

				if (this.comment.type !== 'comment') {
					classList.push('action-comment', this.comment.type, 'hidden-comment');
				}

				if (this.isAction || this.closed) {
					classList.push('hidden-comment');
				} else {
					classList.push('comment');
				}

				if (this.comment.is_old) {
					classList.push('old-comment');
				}

				if (this.comment.red_point === 'Y') {
					classList.push('redpoint-comment');
				}

				// Copying
				if (this.copyingTimeout) {
					classList.push('comment--copied');
				}

				return classList;
			},

			isAction() {
				return this.comment.type !== 'comment';
			},

			actionType() {
				return this.comment.type.replace('action-', '');
			},
		},

		/**
		 * Lifecycle Hook.
		 */
		mounted() {
			this.$element     = $(this.$el);
			this.$commentBody = $(this.$refs.commentBody);

			this.subscribe();

			this.$element.on('mousedown mouseup keydown', (event) => {
				this.updateTargetComment(this.comment.id);
			});
		},

		/**
		 * Watched Props.
		 */
		watch: {
			diff: {
				handler(value) {
					const $commentRevisionDiffContainer = this.$element.find('.comment__diff');

					if (! value) {
						$commentRevisionDiffContainer.empty();
					} else {
						this.preview = null;

						const diffHTML = Diff2Html.getPrettyHtml(value);
						$commentRevisionDiffContainer.html(diffHTML);
					}
				}
			},

			message: {
				handler() {
					if (! this.messageTimeout) {
						return;
					}

					setTimeout(() => {
						this.resetMessage();
					}, this.messageTimeout);
				}
			},

			comment: {
				immediate: true,
				handler() {
					this.updatePreview();
				},
			},

			preview: {
				immediate: true,
				handler() {
					Vue.nextTick(() => {
						this.parseTaskLists();

						if (! this.isRevision) {
							this.completedTasklistItems = this.getCompletetedTaskListItemsCount();
							this.totalTasklistItems     = this.getTotalTaskListItemsCount();

							this.bindChangeListeners();
						} else {
							this.disableTaskLists();
						}
					});
				}
			},
		},

		/**
		 * Component Methods.
		 */
		methods: {
			...mapActions({
				fetchComment: 'comments/fetchComment',
				deleteComment: 'comments/deleteComment',
				updateComment: 'comments/updateComment',
				updateTargetComment: 'comments/updateTargetComment',
			}),

			...mapMutations({
				refreshComment: 'comments/COMMENT_REFRESH',
			}),

			upperFirst,

			$can,

			/**
			 * Beam Subscription.
			 */
			subscribe() {
				const beam = window.$$beam;
				const channel = `task-comment-${this.comment.id}`;

				beam.join(channel);
				beam.on(channel, 'task-comment:updated', this.handleCommentUpdated);
			},


			/**
			 * Event Listeners.
			 */
			handleCommentUpdated(comment) {
				if (! this.isInlineEditting) {
					this.setMessage('Коментарът беше редактиран.', 'success');
				}

				this.refreshComment({
					commentId: comment.id,
					comment: comment,
				});
			},

			handleReset() {
				this.preview    = this.comment.body;
				this.isRevision = false;
				this.diff       = null;
			},

			handleRevisionSwitch({ revision }) {
				this.preview    = revision.body;
				this.isRevision = true;
				this.diff       = null;
			},

			onEdit() {
				this.setMessage('Зарежда се..', 'default', 'fa-spinner fa-spin', false);

				this.fetchComment(this.comment.id)
					.then((data) => {
						this.resetMessage();

						this.editedBody       = this.comment.body_raw;
						this.isInlineEditting = true;
						this.editedRevision   = this.comment.last_revision_id;
					});
			},

			onSave(value) {
				this.isSaving = true;

				this.updateComment({
					commentId: this.comment.id,
					value: value,
					revision: this.editedRevision,
				})

				.then(({ data }) => {
					this.isSaving         = false;
					this.isInlineEditting = false;
					this.setMessage('Коментарът беше редактиран.', 'success');

					this.diff             = null;
					this.editedRevision   = null;
				})

				.catch(({ response }) => {
					const { data } = response;

					this.isSaving       = false;
					this.editedRevision = this.comment.last_revision_id;

					this.handleUpdateConflictDiff(data);
				});
			},

			handleLoadRevisionDiff({ revision1, revision2 }) {
				fetchCommentDiff({
						revision1,
						revision2,
					})
					.then(({ data }) => {
						this.diff = data;
					});
			},

			handleUpdateConflictDiff(data) {
				this.message        = data.message;
				this.messageType    = 'error';
				this.diff           = data.diff;
				this.editedRevision = this.comment.last_revision_id;
			},


			/**
			 * Other Methods.
			 */
			updatePreview() {
				let preview = this.comment.body_raw;
				if (['comment', 'action-orbit-review', 'action-migration'].indexOf(this.comment.type) !== -1) {
					preview = this.comment.body;
				}

				this.preview = preview;
			},

			parseTaskLists() {
				Vue.nextTick(() => {
					$('.parsedown-task-list-item', this.$commentBody).each(function () {
						const escapedHtml = $(this).html();
						const decodedHtml = decodeHtml(escapedHtml);
						const sanitizedHtml = DOMPurify.sanitize(decodedHtml, sanitizeConfig);

						$(this).html(sanitizedHtml);
					});
				})
			},

			disableTaskLists() {
				Vue.nextTick(() => {
					const $taskListItems = $('.parsedown-task-list-item', this.$commentBody);

					$taskListItems.find('input[type="checkbox"]').attr('disabled', 'disabled');
				});
			},

			bindChangeListeners() {
				Vue.nextTick(() => {
					const $taskListItems = $('.parsedown-task-list-item input[type="checkbox"]', this.$commentBody);

					$taskListItems.on('change', (event) => {
						let $taskListItem       = $(event.target);
						let $taskListItemParent = $taskListItem.closest('.parsedown-task-list-item');
						let isChecked           = $taskListItem.is(':checked');
						let taskListItemIndex   = this.$element.find('.parsedown-task-list-item').index($taskListItemParent);

						this.$element.find('.parsedown-task-list input[type="checkbox"]').attr('disabled', 'disabled');

						this.diff  = null;
						this.updateTaskListItem(taskListItemIndex, isChecked);
					});
				});
			},

			getCompletetedTaskListItemsCount() {
				return this.$element.find('.parsedown-task-list-item-close').length;
			},

			getTotalTaskListItemsCount() {
				return this.$element.find('.parsedown-task-list-item').length;
			},

			updateTaskListItem(taskListIndex, isChecked) {
				this.isSaving = true;

				updateTaskListItem({
					commentId: this.comment.id,
					taskListIndex: taskListIndex,
					isChecked: isChecked,
					revision: this.comment.last_revision_id,
				})

				.then(({ data }) => {
					this.isSaving = false;
					this.refreshComment({
						commentId: this.comment.id,
						comment: data,
					});
				})

				.catch(({ response: { data } }) => {
					this.isSaving = false;

					$('.parsedown-task-list input[type="checkbox"]', this.$commentBody)
						.attr('disabled', false);

					this.handleUpdateConflictDiff(data);
				});
			},

			forceOpen() {
				if (this.comment.type === 'comment') {
					this.closed = false;
					this.$el.classList.remove('hidden-comment');
					this.$el.classList.add('comment');
				}
			},

			toggleClose(event) {
				if (event.target.closest('.comment__actions')) {
					return;
				}

				if (this.comment.body_raw.length === 0 && this.comment.files.length === 0 && this.comment.type !== 'action-time-estimate') {
					return;
				}

				this.closed = ! this.closed;

				if (this.closed) {
					this.$el.classList.add('hidden-comment');
					this.$el.classList.remove('comment');
				} else {
					this.$el.classList.remove('hidden-comment');
					this.$el.classList.add('comment');
				}
			},

			setMessage(message, type, icon, timeout) {
				let formattedMessage = message;

				if (timeout !== false && ! timeout) {
					timeout = 2000;
				}

				if (icon) {
					formattedMessage = '<i class="fa ' + icon + '"></i> ' + message;
				}

				this.message        = formattedMessage;
				this.messageType    = type;
				this.messageTimeout = timeout;
			},

			resetMessage() {
				this.message        = null;
				this.messageType    = null;
				this.messageTimeout = null;
			},

			onCopy() {
				this.copyingTimeout = true;

				setTimeout(() => {
					this.copyingTimeout = false;
				}, 500);
			},

			onDelete() {
				if (! window.confirm("Сигурен ли си че искаш да затриеш този коментар?\n(няма undo!)")) {
					return;
				}

				this.isDeleting = true;

				this.deleteComment(this.comment.id)
					.then(() => {
						this.isDeleting = false;
					});
			}
		}
	}
</script>
