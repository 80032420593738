<template>
	<div class="site-logs" ref="logs">
		<p v-if="remaining">... {{ remaining }} lines remaining ...</p>

		<p v-for="logline of this.logLines" :key="eventId + '_' + logline.id">
			<span class="timestamp" :data-status="logline.type">[{{ timestamp(logline.created_at) }}] </span>

			<span v-html="logline.body.replace(/\\n/g, '<br/>')"></span>
		</p>

		<p class="indicator" v-if="pollTimeout"><span>.</span><span>.</span><span>.</span></p>
	</div>
</template>

<script>
export default {
	props: ['initPolling', 'eventId', 'wpDepotId', 'siteId', 'htmlDeployId'],
	emits: ['statusChange'],
	data() {
		return {
			logLines: [],
			lastLogLineId: 0,
			pollTimeout: null,
			remaining: 0,
		}
	},
	watch: {
		eventId: {
			immediate: true,
			handler: function(newValue, oldValue) {
				if ( ! newValue ) return;

				this.logLines = [];

                const url = this.wpDepotId
                    ? `/ajax/task/${window.TASK_ID}/wp-depot/${this.wpDepotId}/site/${this.siteId}/events/${newValue}/logs/all`
                    : `/ajax/task/${window.TASK_ID}/html-deploy/${this.htmlDeployId}/site/${this.siteId}/events/${newValue}/logs/all`;

                fetch(url)
                    .then(r => r.json())
                    .then(result => {
                        this.logLines = result.body.data.reverse();

                        this.lastLogLineId = result.body.data[result.body.data.length - 1]?.id ?? 0;

                        this.remaining = result.body.meta.total - this.logLines.length;

                        if ( this.initPolling ) {
                            this.poll();
                        }
                    });
			}
		}
	},
	updated() {
		if ( this.$refs.logs ) {
			this.$refs.logs.scrollTo(0, this.$refs.logs.scrollHeight);
		}
	},
	methods: {
		poll() {
			const url = this.wpDepotId
                ? `/ajax/task/${window.TASK_ID}/wp-depot/${this.wpDepotId}/site/${this.siteId}/events/${this.eventId}/logs?since_id=${this.lastLogLineId}`
                : `/ajax/task/${window.TASK_ID}/html-deploy/${this.htmlDeployId}/site/${this.siteId}/events/${this.eventId}/logs?since_id=${this.lastLogLineId}`;

			fetch(url).then(r => r.json()).then(result => {
				if ( result.body.data.length ) {
					this.logLines = [...this.logLines, ...(this.lastLogLineId ? result.body.data : result.body.data.reverse())];

					this.lastLogLineId = result.body.data[result.body.data.length - 1].id;
				}

				if ( result.body.meta.event.status === 'completed' || result.body.meta.event.status === 'failed' ) {
					this.stopPolling();
					this.$emit('statusChange', result.body.meta.event.status, result.body.meta.site);
				}
				else if ( result.body.meta.event.status === 'in-progress' ) {
					this.$emit('statusChange', 'in-progress', result.body.meta.site);
				}
			});

			this.pollTimeout = setTimeout(this.poll, 1500);
		},
		stopPolling() {
			clearTimeout(this.pollTimeout);
			this.pollTimeout = null;
		},
		timestamp(timestamp) {
			return renderTimestamp(timestamp);
		}
	}
}
</script>

<style scoped>
	.site-logs {
		height: 100%;
		display: flex;
		flex-direction: column;
		background: #131313;
		color: #FFF;
		font-family: 'Consolas', 'Monaco', monospace;
		gap: 6px;
		line-height: 1.2;
		padding-inline: 8px;
		padding-block: 6px;
		border-radius: 4px;
		overflow: auto;
		min-height: inherit;
		max-height: inherit;
	}

	.timestamp[data-status="ERR" i] {
		color: #FF3333;
	}

	.timestamp[data-status="OUT" i] {
		color: #7FEFBD;
	}

	.indicator span:not(first-child) {
		animation: loading 2000ms ease-in-out infinite;

		&:nth-child(2) {
			animation-delay: 500ms;
		}

		&:last-child {
			animation-delay: 1000ms;
		}
	}

	@keyframes loading {
		0% {
			opacity: 0;
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}
</style>
