<template>
	<div id="html-deploy-container" class="html-deploy-container">
        <a class="metalink" href="#" @click="handleClick">
            <i :class="'fa ' + buttonIcon"></i>{{ buttonLabel }}
        </a>

		<dialog @click="closeDialog" ref="dialog" class="event-log-popup" v-show="deployEvent !== null">
			<div @click.stop class="event-log-popup-inner">
				<div class="event-log-popup-bar">
					<p>Logs for <span>{{ deployEvent?.type }}</span> event initiated by {{ deployEvent?.initiator }}</p>

					<button class="submit" @click="closeDialog">Close</button>
				</div>

				<div class="site-logs-wrapper">
					<SiteLogs
                        :initPolling="deployEvent?.status === 'in-progress' || deployEvent?.status === 'pending'"
                        :eventId="deployEvent?.id"
                        :siteId="siteId"
                        :htmlDeployId="htmlDeployId"
                        @statusChange="handleStatusChange"
                    />
				</div>
			</div>
		</dialog>
	</div>
</template>

<script>
/**
 * The external dependencies.
 */
import { mapGetters } from 'vuex';

/**
 * The internal dependencies.
 */
import SiteLogs from '~/components/wp-depot/SiteLogs.vue';

export default {
    components: { SiteLogs },
    data() {
        return {
            repoUrl: this.$parent.repoUrl,
            name: this.$parent.projectName,
            htmlDeployId: this.$parent.htmlDeployId,
            status: this.$parent.htmlDeployStatus,
            deployEvent: null,
            siteId: 0,
        }
    },
    mounted() {
		const url = `/ajax/task/${window.TASK_ID}/html-deploy/${this.htmlDeployId}`;
        if (this.status === 'creating') {
			fetch(url).then(r => r.json()).then(result => {
				this.deployEvent = result.site.data.events.find(x => x.type === 'create');
                this.status = result.site.data.status;
			})
        }
		else if (this.htmlDeployId) {
			fetch(url).then(r => r.json()).then(result => {
				this.deployEvent = result.site.data.events.findLast(x => x.status === 'in-progress') ?? null;
				this.status = result.site.data.status;
			})
		}
    },
    computed: {
        buttonIcon() {
            if ( ['creating', 'pending'].includes(this.status) ) {
                return 'fa-spinner fa-spin';
            } else {
                return 'fa-arrow-circle-up';
            }
        },
        buttonLabel() {
            if ( ['creating', 'pending'].includes(this.status) ) {
                return 'Deploying';
            } else {
                return 'Deploy';
            }
        }
    },
    methods: {
        handleClick() {
            if ( ['creating', 'pending'].includes(this.status) ) {
			    this.$refs.dialog.showModal();
            } else {
                this.handleDeploy();
            }
        },
        closeDialog() {
			this.$refs.dialog.close();
        },
        handleDeploy() {
            this.status = 'pending';

            fetch(`/ajax/task/${window.TASK_ID}/html-deploy`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(result => {
                this.deployEvent = result.event;
                this.siteId = result.site_id;
                this.status = result.html_deploy_status;
                this.htmlDeployId = result.html_deploy_id;
			    this.$refs.dialog.showModal();
            })
            .catch(error => {
                console.error(error);
                alert("something is wrong! See console. ");
            })
        },
		handleStatusChange(siteEventNewStatus) {
			if ( siteEventNewStatus ) {
				this.status = siteEventNewStatus === 'in-progress' ? 'creating' : siteEventNewStatus;
			}
		},
    }
}
</script>

<style scoped>
	.html-deploy-container {
        display: inline-block;
	}

	& dialog {
		margin: auto;
		border: 0;
		border-radius: 6px;
		min-width: 1024px;
		max-width: 1024px;
		overflow: visible;

		&::backdrop {
			background: rgba(0,0,0,.5);
		}
	}

	.site-logs-wrapper {
		min-height: 300px;
		max-height: 720px;
	}

	.event-log-popup-bar {
		display: flex;
		align-items: center;
		padding-inline: 12px;
		padding-block: 8px;
		line-height: 1;
		background: #FCFCFC;
		border-radius: 4px 4px 0 0;

		& span {
			text-transform: capitalize;
		}

        & p {
            margin-bottom: 0;
        }

		& button {
			margin-left: auto;
		}
	}

    .submit {
        appearance: none;
        border: 1px solid #b2b2b2;
        border-radius: 4px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;
        background: #f5f5f5;
        display: inline-block;
        vertical-align: top;
        padding: 4px 7px;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                border: 1px solid #888;
            }
        }
    }
</style>
