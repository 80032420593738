<template>
	<div class="checklists">
		<div class="checklists__head">
			<h4 class="checklists__title">Checklists</h4><!-- /.checklists__title -->

			<a v-if="isAdmin" href="#" class="btn" @click.prevent="addingNew = true">
				<i class="fa fa-plus"></i> Add New Checklist
			</a>
		</div><!-- /.checklists__head -->

		<div v-if="totalEstimate > 0" class="checklists__progress progress">
			<div class="checklists__progress-bar progress-bar" :style="{ width: `${progressValue}%` }">
				ETA Progress: ({{progressValue}}%)
			</div>

			<div class="checklists__progress-total">ETA: {{estimatePretty}}</div><!-- /.checklists__progress-total -->
		</div><!-- /.checklists__progress -->

		<div class="checklists__new" v-if="addingNew">
			<checklist-new :taskId="taskId" @checklist:created="handleChecklistCreated" @checklist:cancel="addingNew = false" />
		</div><!-- /.checklists__new -->

		<div class="checklists__body">
			<i v-if="!checklists" class="fa fa-spinner fa-spin"></i>

			<checklist v-for="(checklist, index) in checklists" :key="checklist.id + index" :authorId="checklist.author_id" v-model="checklists[index]" @delete="handleChecklistDelete" @update="handleChecklistUpdate" />
		</div><!-- /.checklists__body -->
	</div>
</template>

<script>
import yaml from 'js-yaml';

import ChecklistEntry from '../models/ChecklistEntry';
import Checklist from '../checklist-components/Checklist.vue';
import ChecklistNew from '../checklist-components/ChecklistNew.vue';

import Beam from '../lib/Beam';

let updateInterval = null;
let fetchRequest = null;

export default {
	name: 'checklists-container',

	components: {
		Checklist,
		ChecklistNew,
	},

	data() {
		return {
			checklists: null,
			addingNew: false,
			$etaContainer: null,
		};
	},

	computed: {
		taskId() {
			// Global variable
			return TASK_ID;
		},

		cheklistsBody() {
			return this.checklists ? this.checklists.map(checklist => checklist.body) : null;
		},

		totalEstimate() {
			return this.checklists ? this.sumEstimates(this.cheklistsBody) : 0;
		},

		totalEstimateUnchecked() {
			return this.checklists ? this.sumEstimates(this.cheklistsBody, 'unchecked') : 0;
		},

		progressValue() {
			if (!this.totalEstimate) {
				return 0;
			}

			return Math.ceil((this.totalEstimate - this.totalEstimateUnchecked) / this.totalEstimate * 100);
		},

		estimatePretty() {
			return this.formatEstimate(this.totalEstimate);
		},

		estimateCheckedPretty() {
			return this.formatEstimate(this.totalEstimate - this.totalEstimateUnchecked);
		},

		/**
		 * Whether current user is admin.
		 *
		 * @return {Boolean}
		 */
		isAdmin() {
			return window.user_is_admin;
		}
	},

	methods: {
		parseChecklist(_yaml) {
			return yaml.load(_yaml);
		},

		fetchChecklists() {
			return new Promise((resolve, reject) => {
				if (!!fetchRequest && fetchRequest.abort) {
					fetchRequest.abort();
				}

				fetchRequest = $.ajax({
					url: '/ajax/checklists/fetch/task/' + this.taskId,
					success: (response) => {
						const checklists = response.data.map(checklist => {
							if (checklist.body) {
								checklist.body = yaml.load(checklist.body);
							}

							return checklist;
						});

						this.checklists = checklists;
					},
					error: () => {
						if (!this.checklists) {
							this.checklists = [];
						}
					},

					complete: (response) => {
						resolve();
						fetchRequest = null;
					}
				});
			})
		},

		refreshChecklists() {
			if (!!fetchRequest && fetchRequest.abort) {
				fetchRequest.abort();
			}

			fetchRequest = $.ajax({
				url: '/ajax/checklists/fetch/task/' + this.taskId,
				success: (response) => {
					const checklists = response.data.map(checklist => {
						if (checklist.body) {
							checklist.body = yaml.load(checklist.body);
						}

						return checklist;
					});

					checklists.forEach(checklist => {
						const checklistIndex = this.checklists.findIndex( _checklist => checklist.id === _checklist.id );

						if (checklistIndex === -1) {
							this.checklists.push(checklist);
						} else if (checklist.updated_at !== this.checklists[checklistIndex].updated_at) {
							Vue.set(this.checklists, checklistIndex, checklist);
						}
					});

					if (this.checklists.length !== checklists.length) {
						const checklistIds = checklists.map(checklist => checklist.id);

						this.checklists = this.checklists.filter(checklist => checklistIds.includes(checklist.id));
					}
				},
			});
		},

		setRefreshInterval() {
			// clearInterval(updateInterval);
			// updateInterval = setInterval(() => {
			// 	this.refreshChecklists();
			// }, 60000);
		},

		handleChecklistCreated(checklist) {
			checklist.body = yaml.load(checklist.body);
			checklist.isNew = true;

			this.checklists.push(checklist);
			this.addingNew = false;
		},

		handleChecklistDelete(checklistId) {
			const index = this.checklists.findIndex(checklist => checklist.id === checklistId);

			if (index >= 0) {
				this.checklists.splice(index, 1);
			}
		},

		handleChecklistUpdate() {
			this.setRefreshInterval();
		},

		sumEstimates(data, state) {
			if (!data) {
				return 0;
			}

			let total = 0;

			if (typeof data === 'string') {
				const entry = new ChecklistEntry(data);

				if (!state || state === entry.state) {
					total += entry.getEstimate();
				}
			} else {
				Object.keys(data).forEach(key => {
					total += this.sumEstimates(data[key], state);
				});
			}

			return total;
		},

		formatEstimate(time) {
			return (time / 60).toFixed(2);
		},

		handleChecklistUpdated(data) {
			Vue.nextTick(() => {
				setTimeout(() => {
					const checklistId = data.id;
					const checklist = this.checklists.find(checklist => checklist.id.toString() === checklistId.toString());

					const localTimestamp = new Date(checklist.updated_at);
					const remoteTimestamp = new Date(data.updated_at);

					if (localTimestamp < remoteTimestamp) {
						if (!!fetchRequest && fetchRequest.abort) {
							fetchRequest.abort();
						}

						fetchRequest = $.ajax({
							url: `/ajax/checklists/${checklistId}`,
							type: 'GET',
							success: (resp) => {
								const checklistIndex = this.checklists.findIndex(checklist => checklist.id.toString() === checklistId.toString());

								const checklistUpdate = resp.data;
								checklistUpdate.body = yaml.load(checklistUpdate.body);

								const checklist = Object.assign({}, this.checklists[checklistIndex], {
									update: checklistUpdate,
								});

								if (checklistIndex >= 0) {
									Vue.set(this.checklists, checklistIndex, checklistUpdate);
								}
							},
						})
					}
				}, 10);
			});
		},

		handleChecklistAdded(data) {
			setTimeout(() => {
				const checklist = data.data;
				const checklistId = checklist.id;
				checklist.body = yaml.load(checklist.body);

				const checklistIndex = this.checklists.findIndex(checklist => checklist.id.toString() === checklistId.toString());

				if (checklistIndex === -1) {
					this.checklists.push(checklist);
				}
			}, 100);
		},

		beamInit() {
			const beam = window.$$beam;

			beam.join(`task-${this.taskId}`);
		},

		beamBind() {
			const beam = window.$$beam;

			beam.on('checklist:updated', this.handleChecklistUpdated);
			beam.on('checklist:added', this.handleChecklistAdded);
			beam.on('checklist:deleted', this.handleChecklistDelete);
		},
	},

	created() {
		this.beamInit();
		this.beamBind();
	},

	mounted() {
        this.fetchChecklists();
		this.setRefreshInterval();
	}
}

</script>
