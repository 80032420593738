/**
 * The internal dependencies.
 */
import { getEntry, getMainEntries } from './getters';
import { ACCESS_DEPOT_UNSAVED_ID } from '~/lib/constants';
import {
	getPathPartsIndexesFromFileTree,
	mergeFileTreesByDirectories,
	resetSelectedDirectoriesInFileTree,
	transformFileTree,
	selectFileTreeItemByPath,
	getWpRootFromPathParts,
	clearFileTreeNodeLoading,
} from '../../helpers/access-depot';

export const ACCESS_DEPOT_UPDATING = (state, { id }) => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return;
	}

	entry.isLoading = true;
	entry.message = '';
};

export const ACCESS_DEPOT_UPDATING_SUCCESS = (state, { id }) => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return;
	}

	entry.isLoading = false;
	entry.message = 'Update successful';
	entry.messageType = 'success';
};

export const ACCESS_DEPOT_UPDATING_ERROR = (state, { id, message }) => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return;
	}

	entry.isLoading = false;
	entry.message = `Error while updating: ${message}`;
	entry.messageType = 'error';
};

export const ACCESS_DEPOT_DELETING = (state, { id }) => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return;
	}

	entry.isLoading = true;
	entry.message = '';
};

export const ACCESS_DEPOT_DELETING_SUCCESS = (state, { id }) => {
	const index = state.entries.findIndex(entry => entry.id === id);
	if (index === -1) {
		return;
	}
	const entry = state.entries[index];

	entry.isLoading = false;
	state.entries = [
		...state.entries.slice(0, index),
		...state.entries.slice(index + 1, state.entries.length)
	];
};

export const ACCESS_DEPOT_DELETING_ERROR = (state, { id, message }) => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return;
	}

	entry.isLoading = false;
	entry.message = message;
	entry.messageType = 'error';
};

export const ACCESS_DEPOT_ADD = (state, { entry }) => {
	state.entries.push(entry);
};

export const ACCESS_DEPOT_REMOVE = (state, { id }) => {
	const index = state.entries.findIndex(entry => entry.id === id);
	if (index === -1) {
		return;
	}
	const entry = state.entries[index];

	state.entries = [
		...state.entries.slice(0, index),
		...state.entries.slice(index + 1, state.entries.length)
	];
};

export const ACCESS_DEPOT_SET_IS_ADDING = (state, { isAdding }) => {
	state.isAdding = isAdding;
};

export const ACCESS_DEPOT_SET_IS_ACTIVE = (state, { id, isActive }) => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return;
	}

	entry.isActive = isActive;
};

export const ACCESS_DEPOT_SET_FORM_CHANGED = (state, { id, changed }) => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return;
	}

	entry.formChanged = changed;
};

export const ACCESS_DEPOT_SAVING = (state) => {
	const entry = getEntry(state)(ACCESS_DEPOT_UNSAVED_ID);
	if (!entry) {
		return;
	}

	entry.isLoading = true;
	entry.message = '';
};

export const ACCESS_DEPOT_RESERVATIONS_UPDATED = (state, { entry: updatedEntry }) => {
	const entry = getEntry(state)(updatedEntry.id);
	if (!entry) {
		return;
	}
	entry.active_reservations = updatedEntry.active_reservations;
}


export const ACCESS_DEPOT_SAVING_SUCCESS = (state, { id }) => {
	const entry = getEntry(state)(ACCESS_DEPOT_UNSAVED_ID);
	if (!entry) {
		return;
	}

	entry.isLoading = false;
	state.isAdding = false;
	entry.message = 'Credentials saved successfully.';
	entry.messageType = 'success';
	entry.id = id;
};

export const ACCESS_DEPOT_SAVING_ERROR = (state, { message }) => {
	const entry = getEntry(state)(ACCESS_DEPOT_UNSAVED_ID);
	if (!entry) {
		return;
	}

	entry.isLoading = false;
	state.isAdding = false;
	entry.message = `Error while saving credentials: ${message}`;
	entry.messageType = 'error';
};

export const ACCESS_DEPOT_RESET_IS_MAIN = (state, { exclude }) => {
	getMainEntries(state)
		.forEach(entry => {
			if (entry.id === exclude) {
				return;
			}

			entry.is_main = false;
		});
};

export const ACCESS_DEPOT_SET_IS_MAIN = (state, { id, is_main }) => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return;
	}

	entry.is_main = is_main;
};

export const ACCESS_DEPOT_FTP_EXPLORER_SET_STATUS = (state, { isLoading, message }) => {
	if (!state.ftpExplorer) {
		return;
	}
	state.ftpExplorer.status = {
		isLoading,
		message
	};
};

export const ACCESS_DEPOT_FTP_EXPLORER_SET_IS_VISIBLE = (state, { isVisible }) => {
	if (!state.ftpExplorer) {
		return;
	}
	state.ftpExplorer.isVisible = isVisible;
};

export const ACCESS_DEPOT_FTP_EXPLORER_SET_ENTRY_ID = (state, { entryId }) => {
	if (!state.ftpExplorer) {
		return;
	}
	state.ftpExplorer.entryId = entryId;
};

export const ACCESS_DEPOT_FTP_EXPLORER_SET_FILE_TREE = (state, { fileTree }) => {
	if (!state.ftpExplorer) {
		return;
	}

	let newFileTree;
	let transformedFileTree = transformFileTree(fileTree);

	const currentDirectory      = state.ftpExplorer.currentDirectory;
	const currentDirectoryParts = currentDirectory.split(state.ftpExplorer.directorySeparator).filter(Boolean);
	const oldFileTree           = state.ftpExplorer.fileTree;

	// we don't have an file tree, create one from the new fileTree
	if (!oldFileTree.length) {
		newFileTree = transformedFileTree;
		newFileTree = selectFileTreeItemByPath(newFileTree, currentDirectoryParts);
	} else { // we have an existing file tree, we should merge it with the new fileTree
		let updatedOldFileTree = resetSelectedDirectoriesInFileTree(oldFileTree);
		newFileTree = mergeFileTreesByDirectories(updatedOldFileTree, transformedFileTree, currentDirectoryParts);
	}

	newFileTree = clearFileTreeNodeLoading(newFileTree);

	state.ftpExplorer.fileTree = newFileTree;
};

export const ACCESS_DEPOT_FTP_EXPLORER_RESET_FILE_TREE = (state, { fileTree }) => {
	if (!state.ftpExplorer) {
		return;
	}

	state.ftpExplorer.fileTree = fileTree;
}

export const ACCESS_DEPOT_FTP_EXPLORER_SET_CURRENT_DIR = (state, { currentDirectory }) => {
	if (!state.ftpExplorer) {
		return;
	}

	state.ftpExplorer.currentDirectory = currentDirectory;
};

export const ACCESS_DEPOT_FTP_EXPLORER_SET_WP_ROOT = (state, { wpRoot }) => {
	if (!state.ftpExplorer) {
		return;
	}

	state.ftpExplorer.wpRoot = wpRoot;
};

export const ACCESS_DEPOT_FTP_EXPLORER_REFRESH_WP_ROOT = (state) => {
	if (!state.ftpExplorer) {
		return;
	}

	// this is done, as the root can also be a WordPress instance
	// but we don't have it in the fileTree
	let isRootWp = false;
	for (let item of state.ftpExplorer.fileTree) {
		if (item.title === 'wp-config.php') {
			isRootWp = true;
			break;
		}
	}

	const currentDirectory   = state.ftpExplorer.currentDirectory;
	const directorySeparator = state.ftpExplorer.directorySeparator;
	const pathParts          = getPathPartsIndexesFromFileTree(currentDirectory.split(directorySeparator).filter(Boolean), state.ftpExplorer.fileTree);

	const wpRootPathFromNode = getWpRootFromPathParts(pathParts.slice(0), state.ftpExplorer.fileTree.slice(0), directorySeparator);
	let wpRootPath;
	if (!wpRootPathFromNode && isRootWp) {
		wpRootPath = '/';
	} else if (wpRootPathFromNode) {
		wpRootPath = wpRootPathFromNode;
	}

	state.ftpExplorer.wpRoot = wpRootPath;
};

export const ACCESS_DEPOT_FTP_EXPLORER_SET_AJAX_SOURCE = (state, { ajaxSource }) => {
	if (!state.ftpExplorer) {
		return;
	}

	state.ftpExplorer.ajaxSource = ajaxSource;
};

export const ACCESS_DEPOT_FTP_EXPLORER_SHOW_INPUT_LOADER = (state, { showInputLoader }) => {
	if (!state.ftpExplorer) {
		return;
	}

	state.ftpExplorer.showInputLoader = showInputLoader;
};

export const ACCESS_DEPOT_LOADED_TOTPS = (state, { id, totps }) => {
	const entry = getEntry(state)(id);
	entry.totps = totps.map(totp => ({
		...totp,
		validity: Math.floor(new Date(totp.validity).getTime() / 1000)
	}));
}

export const ACCESS_DEPOT_FOCUS = (state, { id }) => {
	state.entries = [
        ...state.entries.map(entry => {
            entry.isFocused = entry.id === id;
            return entry;
        })
	];
}

export const ACCESS_DEPOT_SET_ACTIVE_RESERVATIONS = (state, { id }) => {
	const findIndex = state.entries.findIndex(item => item.id === id);
	const entry = state.entries.find(item => item.id === id);
	const newEntry = {
		active_reservations: [],
		...entry
	}
	Vue.set(state.entries, findIndex, newEntry);
}

export const ACCESS_DEPOT_SET_FIRST_FOCUS = (state) => {
	if(state.entries.length) {
		state.entries[0].isFocused = true;
	}
}

export const ACCESS_DEPOT_RESET_VALUES = (state, {entry}) => {
	state.entries = [
		...state.entries.map(item => {
			if(item.id === entry.id) {
				return entry
			}
			return item;
		})
	]
}

export const ACCESS_DEPOT_SET_LATEST_RESERVATIONS = (state, { data: { reserved_seats } }) => {
	const reservedEntries = Object.values(reserved_seats);
	state.entries = [
		...state.entries.map(entry => {
			const findReserve = reservedEntries.find(reserve => reserve.id === entry.id);
			if(findReserve) {
				return {
					...findReserve,
					isFocused: entry.isFocused, 
					isActive: entry.isActive
				};
			} else if(entry.active_reservations.length) {
				entry.active_reservations = [];	
			}
			return entry;
		})
	]
}

export const ACCESS_DEPOT_UPDATING_ENTRY = (state, { data }) => {
	state.entries = [
		...state.entries.map(entry => {
			if(entry.id === data.id) {
				return {
					...entry,
					...data
				};
			}
			return entry;
		})
	]
}

export const ACCESS_DEPOT_SET_PASSWORD = (state, { data, id }) => {
	const entry = getEntry(state)(id);
	entry.password = data.password;
	entry.password_hidden = false;
}